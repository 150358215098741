(function ($) {
  Drupal.behaviors.basicCarouselFormatter = {
    attach: function (context) {
      var $formatter = $('.basic-carousel-formatter', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var thisFormatter = $(this);
        var carousel = thisFormatter.find('.js-basic-carousel');
        var arrowsDiv = thisFormatter.find('.carousel-controls');
        var dotsDiv = thisFormatter.find('.carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';
        var useResponsive = false;
        var respDots = false;
        var respArrows = true;
        // autoplay settings
        var autoplay = carousel.attr('data-slides-autoplay');

        if (typeof autoplay == 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }

        // slides to show
        var slidesToShow = parseInt(carousel.attr('data-slides-show'));

        // var largeBreakpoint = {};
        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          var useResponsive = true;
        }

        // slides to scroll
        var slidesToScroll = parseInt(carousel.attr('data-slides-scroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }

        // responsive - show dots for smaller screens
        var mobileDots = carousel.attr('data-mobile-dots');

        if (typeof mobileDots != 'undefined') {
          var useResponsive = true;
          var respDots = true;
          var respArrows = false;
        }
        // plain with defaults
        var settingsPlain = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          dots: respDots,
          adaptiveHeight: true,
          autoplay: autoplay
        };
        // case for custom slides count and dots for mobile
        var settingsResponsive = {
          appendArrows: arrowsDiv,
          arrows: respDots ? false : true,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          prevArrow:
            '<span class="slick-svg-wrapper slick-prev" role="button"><svg class="slick-arrow slick-prev"><use xlink:href="#arrow--left"></use></svg></span>',
          nextArrow:
            '<span class="slick-svg-wrapper slick-next" role="button"><svg class="slick-arrow slick-next"><use xlink:href="#arrow--right"></use></svg></span>',
          dots: respDots,
          adaptiveHeight: true,
          autoplay: autoplay,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: respArrows,
                dots: respDots,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: autoplay
              }
            }
          ]
        };

        // Init this carousel with our settings
        if (useResponsive === 'true') {
          carousel.slick(settings);
        } else {
          carousel.slick(settingsResponsive);
        }
        carousel.find('img').on(
          'load',
          _.debounce(function () {
            carousel.slick('resize');
          }, 150)
        );
      });
    }
  };
})(jQuery);
